import React from "react"
import { Link } from "gatsby"
import H4 from "../typography/H4"

const ServiceSidebar = ({ name }) => {
  const services = [
    {
      to: "/leistungen/immobilienbewertung/",
      title: "Immobilienbewertung",
      text:
        "Anim ad velit sint cillum et aliqua exercitation sunt voluptate tempor.",
    },
    {
      to: "/leistungen/expose/",
      title: "Erstellung Exposé",
      text:
        "Anim ad velit sint cillum et aliqua exercitation sunt voluptate tempor.",
    },
    {
      to: "/leistungen/grundriss/",
      title: "Anfertigung Grundriss",
      text:
        "Anim ad velit sint cillum et aliqua exercitation sunt voluptate tempor.",
    },
    {
      to: "/leistungen/vermarktung/",
      title: "Vermarktung auf Internetportalen",
      text:
        "Anim ad velit sint cillum et aliqua exercitation sunt voluptate tempor.",
    },
    {
      to: "/leistungen/energieausweis/",
      title: "Erstellung Energieausweis",
      text:
        "Anim ad velit sint cillum et aliqua exercitation sunt voluptate tempor.",
    },
  ]
  return (
    <div>
      <H4 className="mb-3">Weitere Leistungen:</H4>
      <nav className="space-y-5" aria-label="Sidebar">
        {services.map((entry, i) => {
          return (
            <Link
              to={entry.to}
              className={`flex items-center px-5 py-3  transition-shadow duration-300 rounded-md hover:shadow target:shadow ${name === entry.title
                ? "bg-brand text-white"
                : "bg-gray-100 text-gray-900"
                }`}
              key={i}
            >
              {entry.title}
            </Link>
          )
        })}
      </nav>
    </div>
  )
}

export default ServiceSidebar
