import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const Cta = () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "cta-help.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <div className="relative bg-gray-800">
      <div className="h-56 bg-brand sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <Img
          className="object-cover w-full h-full"
          fluid={data.file.childImageSharp.fluid}
        />
      </div>
      <div className="relative px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <h2 className="text-base font-semibold tracking-wider text-gray-300 uppercase">
            Ihre Zufriedenheit
          </h2>
          <p className="mt-2 text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
            Wir sind da um zu helfen
          </p>
          <p className="mt-3 text-lg text-gray-300">
            Wir sind da für Sie, falls Sie Fragen haben oder uns kontaktieren
            wollen.
          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <Link
                to="/kontakt/"
                className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-gray-900 bg-white border border-transparent rounded-md hover:bg-gray-50"
              >
                Der Weg zu uns
                {/* Heroicon name: external-link */}
                <svg
                  className="w-5 h-5 ml-3 -mr-1 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                  <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cta
