import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layouts/Default"
import Breadcrumb from "../../components/common/Breadcrumb"
import Container from "../../components/layouts/container/Container"
import SectionTitle from "../../components/common/SectionTitle"
import Cta from "../../components/common/Cta"
import HeaderImage from "../../components/common/HeaderImage"
import ColumnsThree from "../../components/layouts/container/ColumnsThree"
import ServiceSidebar from "../../components/common/ServiceSidebar"
import P from "../../components/typography/P"

export default function Page({ data, location }) {
  const svg = {
    check: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    ),
  }
  return (
    <Layout
      location={location}
      title="Vermarktung auf Internetportalen | Döbbel Immobilien"
      name="Leistungen"
      desc=""
    >
      <HeaderImage img={data.header.childImageSharp.fluid} />
      <Breadcrumb
        links={[
          { link: "Leistungen", to: "/leistungen/" },
          {
            link: "Vermarktung auf Internetportalen",
            to: "/leistungen/vermarktung/",
          },
        ]}
      />
      <Container className="my-8 md:my-16">
        <ColumnsThree>
          <div className="md:col-span-2">
            <SectionTitle
              title="Vermarktung auf Internetportalen"
              subtitle="Leistungen"
              text="Quis incididunt cupidatat excepteur labore esse eiusmod."
              className="mb-6"
            />
            <P className="mb-3">
              Die Vermarktung Ihrer Immobilie auf den gängigen Portalen ist
              ratsam. Mittlerweile ist das online Medium der meist besuchte Ort,
              um nach der passenden Immobilie zu suchen.
            </P>
            <P className="mb-3">
              Wir inserieren Ihre Immobilie profesionell in allen gängigen
              Portalen. Zusammen mit einem aussagekräftigen Exposé erhalten Sie
              so eine hoch Sichtbarkeit auf allen Platformen. Sie erhöhen Ihre
              Erfolgschancen damit erheblich.
            </P>
          </div>
          <div>
            <ServiceSidebar name="Vermarktung auf Internetportalen" />
          </div>
        </ColumnsThree>
      </Container>

      <Container className="my-8 md:my-16">
        <Cta />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "vermarktung.jpg" }) {
      childImageSharp {
        fluid(jpegQuality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
